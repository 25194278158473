// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as UserProjectDetailsPanel from "../_components/UserProjectDetailsPanel/UserProjectDetailsPanel.res.js";
import * as UserNetworkSdWanProject_Details_Location from "./UserNetworkSdWanProject_Details_Location.res.js";
import * as ProjectRequiredProducts_CheckmarkableOptions from "../_components/ProjectRequiredProducts/ProjectRequiredProducts_CheckmarkableOptions.res.js";
import * as UserNetworkSdWanProject_Details_RequiredProductsScss from "./UserNetworkSdWanProject_Details_RequiredProducts.scss";
import * as UserNetworkSdWanProject_Details_RequiredProducts_ProductServiceDescription from "./UserNetworkSdWanProject_Details_RequiredProducts_ProductServiceDescription.res.js";

var css = UserNetworkSdWanProject_Details_RequiredProductsScss;

function panelHeading(panelHeading$1) {
  return JsxRuntime.jsx("h4", {
              children: panelHeading$1,
              className: css.panelHeading
            });
}

function UserNetworkSdWanProject_Details_RequiredProducts_ProductPanel$CheckmarkableOptions(props) {
  var options = props.options;
  if (options !== undefined && options.length !== 0) {
    return JsxRuntime.jsx("div", {
                children: JsxRuntime.jsx(ProjectRequiredProducts_CheckmarkableOptions.make, {
                      options: options,
                      cssStart: "StayShaded"
                    }),
                className: css.propertiesContainer
              });
  } else {
    return null;
  }
}

var CheckmarkableOptions = {
  make: UserNetworkSdWanProject_Details_RequiredProducts_ProductPanel$CheckmarkableOptions
};

function UserNetworkSdWanProject_Details_RequiredProducts_ProductPanel(props) {
  var __isExpanded = props.isExpanded;
  var networkSdWanConfiguration = props.networkSdWanConfiguration;
  var isExpanded = __isExpanded !== undefined ? __isExpanded : false;
  var primaryServices = networkSdWanConfiguration.primaryServices;
  var diverseBandWidthValue = networkSdWanConfiguration.diverseBandwidthValue;
  var diverseServices = networkSdWanConfiguration.diverseServices;
  var requirements = networkSdWanConfiguration.additionalRequirements;
  return JsxRuntime.jsx(UserProjectDetailsPanel.make, {
              className: css.panel,
              heading: props.heading,
              isExpanded: isExpanded,
              children: JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsxs(JsxRuntime.Fragment, {
                            children: [
                              JsxRuntime.jsx(UserNetworkSdWanProject_Details_Location.make, {
                                    networkSdWanConfiguration: networkSdWanConfiguration
                                  }),
                              JsxRuntime.jsx("p", {
                                    children: "Primary SD-WAN Service",
                                    className: css.productName
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: JsxRuntime.jsx(UserNetworkSdWanProject_Details_RequiredProducts_ProductServiceDescription.make, {
                                                  label: "Primary Network Type",
                                                  value: networkSdWanConfiguration.primaryNetworkType
                                                }),
                                            className: Cx.cx([
                                                  css.propertyLeft,
                                                  css.stayShaded
                                                ])
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: JsxRuntime.jsx(UserNetworkSdWanProject_Details_RequiredProducts_ProductServiceDescription.make, {
                                                  label: "Bandwidth",
                                                  value: String(networkSdWanConfiguration.primaryBandwidthValue) + (" " + networkSdWanConfiguration.primaryBandwidthUnit)
                                                }),
                                            className: Cx.cx([
                                                  css.propertyRight,
                                                  css.switchShaded
                                                ])
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: JsxRuntime.jsx(UserNetworkSdWanProject_Details_RequiredProducts_ProductServiceDescription.make, {
                                                  label: "Class Of Service",
                                                  value: Belt_Option.getWithDefault(networkSdWanConfiguration.primaryClassOfService, "")
                                                }),
                                            className: Cx.cx([
                                                  css.propertyRight,
                                                  css.switchLight
                                                ])
                                          }),
                                      primaryServices !== undefined ? JsxRuntime.jsx(ProjectRequiredProducts_CheckmarkableOptions.make, {
                                              options: primaryServices,
                                              cssStart: "StayLight"
                                            }) : null
                                    ],
                                    className: css.propertiesContainer
                                  })
                            ]
                          }),
                      JsxRuntime.jsxs(JsxRuntime.Fragment, {
                            children: [
                              JsxRuntime.jsx("p", {
                                    children: "Diverse SD-WAN Service",
                                    className: css.productName
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: JsxRuntime.jsx(UserNetworkSdWanProject_Details_RequiredProducts_ProductServiceDescription.make, {
                                                  label: "Diverse Network Option",
                                                  value: Belt_Option.getWithDefault(networkSdWanConfiguration.diverseNetworkOption, "")
                                                }),
                                            className: Cx.cx([
                                                  css.propertyLeft,
                                                  css.stayShaded
                                                ])
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: diverseBandWidthValue !== undefined ? JsxRuntime.jsx(UserNetworkSdWanProject_Details_RequiredProducts_ProductServiceDescription.make, {
                                                    label: "Bandwidth",
                                                    value: String(diverseBandWidthValue) + (" " + Belt_Option.getWithDefault(networkSdWanConfiguration.diverseBandwidthUnit, ""))
                                                  }) : null,
                                            className: Cx.cx([
                                                  css.propertyRight,
                                                  css.switchShaded
                                                ])
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: JsxRuntime.jsx(UserNetworkSdWanProject_Details_RequiredProducts_ProductServiceDescription.make, {
                                                  label: "Class Of Service",
                                                  value: Belt_Option.getWithDefault(networkSdWanConfiguration.diverseClassOfService, "")
                                                }),
                                            className: Cx.cx([
                                                  css.propertyRight,
                                                  css.switchLight
                                                ])
                                          }),
                                      diverseServices !== undefined ? JsxRuntime.jsx(ProjectRequiredProducts_CheckmarkableOptions.make, {
                                              options: diverseServices,
                                              cssStart: "StayLight"
                                            }) : null
                                    ],
                                    className: css.propertiesContainer
                                  })
                            ]
                          }),
                      JsxRuntime.jsx("p", {
                            children: "SD-WAN Features",
                            className: css.productName
                          }),
                      JsxRuntime.jsx(UserNetworkSdWanProject_Details_RequiredProducts_ProductPanel$CheckmarkableOptions, {
                            options: networkSdWanConfiguration.sdWanFeatures
                          }),
                      JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx("p", {
                                    children: "Additional Service Requirements",
                                    className: css.productName
                                  }),
                              requirements !== undefined ? JsxRuntime.jsx("div", {
                                      children: requirements
                                    }) : null
                            ],
                            className: css.requirementsBlock
                          })
                    ],
                    className: css.panelBody
                  })
            });
}

var make = UserNetworkSdWanProject_Details_RequiredProducts_ProductPanel;

export {
  css ,
  panelHeading ,
  CheckmarkableOptions ,
  make ,
}
/* css Not a pure module */
