// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Global from "Client/common/global";
import * as UserNetworkSdWanProject_Details_RequiredProductsScss from "./UserNetworkSdWanProject_Details_RequiredProducts.scss";

var css = UserNetworkSdWanProject_Details_RequiredProductsScss;

function UserNetworkSdWanProject_Details_Location(props) {
  var networkSdWanConfiguration = props.networkSdWanConfiguration;
  var cityStateCountryToLabel = function (networkSdWanConfiguration) {
    var __x = Belt_Array.keep([
          networkSdWanConfiguration.cityOrTown,
          networkSdWanConfiguration.stateOrProvince,
          networkSdWanConfiguration.country
        ], (function (item) {
            return item !== "";
          }));
    return Js_array.joinWith(", ", __x);
  };
  var addressLabel = function (networkSdWanConfiguration) {
    var __x = Belt_Array.keep([
          Belt_Option.getWithDefault(networkSdWanConfiguration.buildingOrFloor, ""),
          networkSdWanConfiguration.streetAddress,
          Belt_Option.getWithDefault(networkSdWanConfiguration.suite, ""),
          networkSdWanConfiguration.cityOrTown,
          networkSdWanConfiguration.stateOrProvince,
          networkSdWanConfiguration.postalCode,
          networkSdWanConfiguration.country
        ], (function (item) {
            return item !== "";
          }));
    return Js_array.joinWith(", ", __x);
  };
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsx("img", {
                            className: css.mapImage,
                            src: "https://maps.googleapis.com/maps/api/staticmap?center=" + (cityStateCountryToLabel(networkSdWanConfiguration) + ("&zoom=13&size=430x100&maptype=roadmap&key=" + Global.GoogleMapAPI))
                          }),
                      JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx("h4", {
                                    children: "Location",
                                    className: css.cardTitle
                                  }),
                              JsxRuntime.jsx("span", {
                                    children: addressLabel(networkSdWanConfiguration),
                                    className: css.locationText
                                  })
                            ],
                            className: css.locationCard
                          })
                    ],
                    className: css.tileMap
                  }),
              className: css.tileContainer
            });
}

var make = UserNetworkSdWanProject_Details_Location;

export {
  css ,
  make ,
}
/* css Not a pure module */
