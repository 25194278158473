// Generated by ReScript, PLEASE EDIT WITH CARE

import * as User from "../../../../models/User.res.js";
import * as React from "react";
import * as Project from "../../../../models/Project.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as RailsContext from "../../../../models/RailsContext.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as NetworkSdWanProject from "../../../../models/NetworkSdWanProject.res.js";
import * as ProjectsViewContext from "../../../../models/ProjectsViewContext.res.js";
import * as ProjectStickyBarData from "../_components/ProjectStickyBar/ProjectStickyBarData.res.js";
import * as UserNetworkSdWanProject from "./UserNetworkSdWanProject.res.js";

function UserNetworkSdWanProject__JsBridge$default(props) {
  var railsContext = props.railsContext;
  var jsProps = props.jsProps;
  var railsContext$1 = React.useMemo((function () {
          return RailsContext.fromJson(railsContext);
        }), [railsContext]);
  var project = React.useMemo((function () {
          return NetworkSdWanProject.fromJson(jsProps.project);
        }), [jsProps.project]);
  var viewContext = React.useMemo((function () {
          return ProjectsViewContext.fromJson(jsProps.viewContext);
        }), [jsProps.viewContext]);
  var projectStickyBarData = React.useMemo((function () {
          return ProjectStickyBarData.fromJson(jsProps.projectStickyBar);
        }), [jsProps.projectStickyBar]);
  var concierges = React.useMemo((function () {
          return Belt_Array.map(jsProps.concierges, User.fromJson);
        }), [jsProps.concierges]);
  var estimatedDates = React.useMemo((function () {
          return Project.EstimatedDates.fromJson(jsProps.estimatedDates);
        }), [jsProps.estimatedDates]);
  return JsxRuntime.jsx(UserNetworkSdWanProject.make, {
              project: project,
              concierges: concierges,
              canSendProposals: jsProps.canSendProposals,
              viewContext: viewContext,
              userRole: railsContext$1.userRole,
              projectStickyBarData: projectStickyBarData,
              desktop: railsContext$1.desktop,
              tablet: railsContext$1.tablet,
              mobile: railsContext$1.mobile,
              estimatedDates: estimatedDates
            });
}

var $$default = UserNetworkSdWanProject__JsBridge$default;

export {
  $$default as default,
}
/* User Not a pure module */
