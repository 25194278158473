// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Date from "../libs/Date.res.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              startDate: field.required("startDate", $$Date.Naive.decoder),
              contractLength: field.required("contractLength", Json_Decode$JsonCombinators.$$int),
              streetAddress: field.required("streetAddress", Json_Decode$JsonCombinators.string),
              buildingOrFloor: field.required("buildingOrFloor", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)),
              suite: field.required("suite", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)),
              cityOrTown: field.required("cityOrTown", Json_Decode$JsonCombinators.string),
              subLocality: field.required("subLocality", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)),
              stateOrProvince: field.required("stateOrProvince", Json_Decode$JsonCombinators.string),
              country: field.required("country", Json_Decode$JsonCombinators.string),
              postalCode: field.required("postalCode", Json_Decode$JsonCombinators.string),
              prefix: field.required("prefix", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)),
              additionalLocationDetails: field.required("additionalLocationDetails", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)),
              primaryNetworkType: field.required("primaryNetworkType", Json_Decode$JsonCombinators.string),
              primaryBandwidthValue: field.required("primaryBandwidthValue", Json_Decode$JsonCombinators.$$int),
              primaryBandwidthUnit: field.required("primaryBandwidthUnit", Json_Decode$JsonCombinators.string),
              primaryClassOfService: field.required("primaryClassOfService", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)),
              primaryServices: field.required("primaryServices", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.array(Json_Decode$JsonCombinators.string))),
              diverseNetworkOption: field.required("diverseNetworkOption", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)),
              diverseBandwidthValue: field.required("diverseBandwidthValue", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.$$int)),
              diverseBandwidthUnit: field.required("diverseBandwidthUnit", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)),
              diverseClassOfService: field.required("diverseClassOfService", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)),
              diverseServices: field.required("diverseServices", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.array(Json_Decode$JsonCombinators.string))),
              sdWanFeatures: field.required("sdWanFeatures", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.array(Json_Decode$JsonCombinators.string))),
              additionalRequirements: field.required("additionalRequirements", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string))
            };
    });

function fromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder));
}

export {
  decoder ,
  fromJson ,
}
/* decoder Not a pure module */
